@import 'grid';
@import 'devices';

@mixin scaleSecondImgFirst {
  @media (max-width: 630px) {
    @content;
  }
}

@mixin scaleSecondImgSecond {
  @media (max-width: 400px) {
    @content;
  }
}

.home_root_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  @include column {
    flex-direction: column;
  }
}

.home_leftSide {
  width: 50%;
  height: 100vh;

  @include column {
    width: 100%;
  }

  @include mobile { margin-bottom: 20px; }
}

.home_rightSide {
  width: 50%;
  height: 100vh;
  display: flex;

  @include column {
    width: 100%;
  }
}

.home_aboutUsHeader {
  font-size: 3.5rem;
}

.img-home-label,
.img-home-label-mobile {
  position: absolute;
  margin: 0;

  color: black;

  font-size: 125px;
  @include tablet { font-size: 100px; }
  @include mobile { font-size: 75px; }
}

.img-home-label {
  right: -110px;
  bottom: 104px;

  transform:rotate(7deg);
  -ms-transform:rotate(270deg);
  -moz-transform:rotate(270deg);
  -webkit-transform:rotate(270deg);
  -o-transform:rotate(270deg);

  opacity: 0;
  transition: opacity .5s;
}

.img-home-label-mobile {
  right: 0;
  left: 0;
  bottom: 10px;

  text-align: center;
  background-color: rgba(255, 255, 255, 0.25);

  opacity: 1;
}

.home_aboutUsContent {
  margin: auto 0 !important;

  @include column {
    text-align: center;
  }
}

.home_button {
  color: #fff;
  font-weight: bold;
  background-color: black;
  border-radius: 5px;
  padding: 15px;

  transition: background-color .3s ease-out;

  &:hover {
    color: #fff;
    background-color: #222222;
  }
}

.home_aboutUsWrapper {
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 30px;

  max-height: 50vh;

  @include desktop {
    max-height: 35vh;
  }

  //scrollbar
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #AFAFAF;
    border-radius: 100px;
  }
}

.home_aboutUsWrapper:after {
  content  : "";
  display: inline-block;
  position : sticky;
  z-index  : 1;
  bottom   : 0;
  left     : 0;
  pointer-events   : none;
  background-image : linear-gradient(to bottom,
          rgba(255,255,255, 0),
          rgba(255,255,255, 1) 90%);
  width    : 100%;
  height: 2em;
}